import Contact from './contact';
import { environment } from '../../../../environments/environment';

export function sendEmail(contact: Contact) {
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
    });

    fetch(`${environment.API_URI}/email`,
        {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(contact)
        })
        .then(res => res.json())
        .then(data => console.log(JSON.stringify(data)));
}
