import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BlogService } from '../../services/blog.service';
import { IBlogPost } from '../../models/blogPost.interface';

@Component({
  selector: 'app-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.scss']
})
export class BlogPostsComponent implements OnInit {
  blogPosts: IBlogPost[] = [];
  cmsUrl = environment.CMS_URI;

  constructor(
      private postService: BlogService,
      private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.blogPosts = this.route.snapshot.data.blogPosts;
  }
}
