import { Component, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
declare let google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit  {

  @ViewChild("mapContainer", { static: false }) gmap: ElementRef;
    map: google.maps.Map;
    lat: any = 49.840205;
    lng: any = 24.004576;

    coordinates = new google.maps.LatLng(this.lat, this.lng);

    mapOptions: google.maps.MapOptions = {
        center: this.coordinates,
        zoom: 8
    };

    marker = new google.maps.Marker({
        position: this.coordinates,
        map: this.map,
        title: "Lviv"
    });

    ngAfterViewInit() {
        this.mapInitializer();
    }

    mapInitializer() {
        this.map = new google.maps.Map(
            this.gmap.nativeElement,
            this.mapOptions
        );
        this.marker.setMap(this.map);
    }

}
