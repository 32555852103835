<div cass="blog-post">
    <div class="page-title-area blog ripple-effect jquery-ripples" [ngStyle]="{ 'background-image': 'url(&quot;' + cmsUrl + blogPost?.cover.url + '&quot;)' }">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h1>{{blogPost?.title}}</h1>
                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li><i class="fas fa-chevron-right"></i></li>
                            <li class="active"><a routerLink="/blog">Blog</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 col-md-12 offset-lg-1">
                    <div class="blog-details">
                        <div class="article-img">
                            <!--<img src="{{cmsUrl}}{{blogPost?.cover.url}}" alt="blog-details">-->
                            <div class="date">{{blogPost?.date | date}}</div>
                        </div>

                        <div class="article-text">
                            <div class="author-share">
                                <div *ngFor="let author of blogPost?.authors"  class="article-author">
                                    <div class="row align-items-center">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="author-img">
                                                <img src="{{cmsUrl}}{{author?.photo.url}}" alt="client">
                                            </div>
                                            <div class="author-title">
                                                <h4>{{author?.name}}</h4>
                                                <span>{{author?.title}}</span>
                                                <br/>
                                                <a [href]="'mailto:' + author?.email">{{author?.email}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul class="category">
                                <li><a *ngFor="let category of blogPost?.category.split(' #')" >#{{category.replace('#', '')}}</a></li>
                            </ul>
                            <markdown class="body" [data]="blogPost?.body" ></markdown>
                        </div>
                    </div>

                    <!--<div class="post-controls-buttons">
                        <div class="controls-left">
                            <a href="#"><i class="fas fa-chevron-left"></i> Prev Post</a>
                        </div>

                        <div class="controls-right">
                            <a href="#">Next Post <i class="fas fa-chevron-right"></i></a>
                        </div>
                    </div>-->

                    <div class="leave-a-reply">
                        <h3 class="title mt-0">Contact us:</h3>
                        <p *ngIf="isShowSentMessage">Thanks for contacting us! We will process your message soon.</p>
                        <div class="row" *ngIf="!isShowSentMessage">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name" placeholder="Full Name" [(ngModel)]="contact.name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" name="email" placeholder="E-Mail" [(ngModel)]="contact.email">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Your Comment" [(ngModel)]="contact.message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" (click)="onSendMessage()">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>