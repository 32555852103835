import gql from 'graphql-tag';
import { ICareer } from '../models/careers.interface';

export interface CareersQueryResponse {
    careers: ICareer[];
}

export const allCareersQuery = () => gql`query allCareers {
  careers {
    header,
    intro,
    description,
  }
}`;