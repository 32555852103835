<div class="page-title-area item-bg-blog ripple-effect jquery-ripples">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h1>Blog</h1>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="active">Blog</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<section id="blog-posts" class="blog-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Our <span>Blog</span></h2>
      <br/>
    </div>

    <div *ngFor="let post of blogPosts" class="single-blog-item" routerLink="/blog/{{post.path}}"
         [ngStyle]="{ 'background-image': 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(&quot;' + cmsUrl + post.cover.url + '&quot;)' }">
      <a routerLink="/blog/{{post.path}}">
        <span>{{post.category}}</span>
        <h3>{{post.title}}</h3>
        <p>{{post.date | date}}</p>
        <p>{{post.intro}}</p>
      </a>
      <a routerLink="/blog/{{post.path}}" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
    </div>

  </div>
</section>
