<section id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Services</span></h2>
            <p>Bitcom is a full cycle web and mobile development provider for the technology companies and startups. Our technology focus covers JavaScript, Angular, React, iOS, Android, Ruby, Go and a various range of other technologies from the Web and Mobile Stacks.</p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-desktop"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>We develop solutions, like single page applications, web apps, mobile web, enterprise platforms.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-mobile-alt"></i>
                    </div>
                    <h3>Mobile Development</h3>
                    <p>We build cross platform mobile apps (React Native, Ionic, Flutter), and mobile web (PWA Ionic, PWA React).</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-users-cog"></i>
                    </div>
                    <h3>Dedicated Team</h3>
                    <p>We scale your in-house development team with our engineers.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-palette"></i>
                    </div>
                    <h3>UI & UX Design</h3>
                    <p>Designing prototypes, wireframes, mockups for the mobile and web apps.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Discovery Phase</h3>
                    <p>Business analysis, drafting visual and architectural concepts.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-award"></i>
                    </div>
                    <h3>Quality Assurance</h3>
                    <p>We provide manual and automated testing services.</p>
                </div>
            </div>
        </div>
    </div>
</section>