import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ICareer } from '../models/careers.interface';
import { CareerService } from '../services/career.service';

@Injectable()
export class CareersResolve implements Resolve<ICareer[]> {
    constructor(private careerService: CareerService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ICareer[]> {
        const careers = this.careerService.getCareers();

        return careers;
    }
}