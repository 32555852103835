<section class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>How We <span>Work</span></h2>
            <p>We provide software development services in the most favorable format, applying agile methodologies, creating dedicated teams to join your in-house team, and building custom software.</p>
        </div>
       
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Getting to know each other</h3>
                    <ul class="features-list">
                        <li>Study your business and your goals</li>
                        <li>Signing NDA, service Agreement, IP license agreement</li>
                        <li>Collecting requirements</li>
                        <li>Setting the scope and timelines</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Starting the project</h3>
                    <ul class="features-list">
                        <li>Gathering the team</li>
                        <li>Starting the project: setting necessary working environment</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-laptop-code"></i>
                    </div>
                    <h3>Development phase</h3>
                    <ul class="features-list">
                        <li>Development of the product according to predetermined stages</li>
                        <li>Regular interaction with the Customer: demos, reporting, testing</li>
                        <li>Drafting documentation for the product</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-bookmark"></i>
                    </div>
                    <h3>Release</h3>
                    <ul class="features-list">
                        <li>Release of the product</li>
                        <li>Adjustment and improvement</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>Maintenance</h3>
                    <ul class="features-list">
                        <li>Supporting the product</li>
                        <li>Receiving feedback and making respective iterations</li>
                        <li>Upgrading to meet new requirements</li>
                        <li>Adding new functionality and features</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>