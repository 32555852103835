<div class="main-banner item-bg-three <!--gradient-bg--> ripple-effect jquery-ripples">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h3><a class="nav-link" href="/blog/Ukraine-lives-fighting-for-its-freedom"><img src="assets/ua-flag.png" class="flag" alt="img">#STANDWITHUKRAINE</a></h3>
                    <br/>
                    <h4>Your Team of Great Minds</h4>
                    <h1>We
                        <a href="" class="typewrite" data-period="2000" data-type='[ "scale your dev team", "develop software", "know mobile and web" ]'>
                            <span class="wrap"></span>
                        </a>
                    </h1>
                    <p>Bitcom is a software development company. We will assist you in developing your digital product and taking it to a new level.</p>
                    <a href="#welcome" class="btn btn-primary">Get Started</a>
                    <a href="#contact" class="btn btn-primary view-work">Get In Touch</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<!--<app-team></app-team>-->

<!--<app-funfacts></app-funfacts>-->

<app-services-two></app-services-two>

<app-how-we-work></app-how-we-work>

<!--<app-blog></app-blog>-->

<app-contact></app-contact>