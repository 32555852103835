<div class="page-title-area item-bg-two ripple-effect jquery-ripples">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h1>Services</h1>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="active">Services</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-services></app-services>
<app-services-two></app-services-two>
