<section id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Founders</span></h2>
        </div>
        <div class="row">
            <div class="col-md-5 offset-md-1">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/tb.jpg" alt="team-img">
                    </div>

                    <div class="content">
                        <h3 class="title">Tetyana Berezenska</h3>
                        <span class="post">CEO</span>
                    </div>

                    <div class="social">
                        <ul>
                            <li><a href="https://www.linkedin.com/in/tetyana-berezenska-19610351" class="fab fa-linkedin-in" target="_blank"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-5 offset-md-1">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/sk.jpg" alt="team-img">
                    </div>

                    <div class="content">
                        <h3 class="title">Sergii Karpiak</h3>
                        <span class="post">CTO</span>
                    </div>

                    <div class="social">
                        <ul>
                            <li><a href="https://www.linkedin.com/in/sergii-karpiak-74a69b4" target="_blank" class="fab fa-linkedin-in" ></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        <!--<div class="team-slides owl-carousel owl-theme">

        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team/tb.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Tetyana Berezenska</h3>
                    <span class="post">COO</span>
                </div>

                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-3.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">James Andy</h3>
                    <span class="post">Back-End Developer</span>
                </div>
                
                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-4.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">David Smith</h3>
                    <span class="post">Front-End Developer</span>
                </div>

                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-5.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">John Doe</h3>
                    <span class="post">Web Developer</span>
                </div>

                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-6.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Olivia Smith</h3>
                    <span class="post">Photographer</span>
                </div>
                
                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        </div>-->
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>