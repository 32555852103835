<section id="about" class="about-area ptb-100">
    <div class="container">
        <!--<div class="section-title">
            <h2>About <span>Bitcom</span></h2>
            <p>We are a custom software development company. We work with technology businesses and individuals to help build and launch their next software product.</p>
        </div>-->
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <<!--div class="section-title">
                        <h2>Located in Ukraine</h2>
                        <p>counting over 170 000 IT specialists, in the city of Lviv, home to 28 000 IT engineers and 4 100 tech graduates each year.</p>
                    </div>-->
                    <div class="section-title">
                        <h2>Team of</h2>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Software Engineers</li>
                        <li><i class="fas fa-check"></i>Test Engineers</li>
                        <li><i class="fas fa-check"></i>Business Analysts</li>
                        <li><i class="fas fa-check"></i>Product Owners</li>
                        <li><i class="fas fa-check"></i>UI/UX Designers</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.jpg" alt="about">
                    
                    <div class="video-btn">
                        <a href="https://www.youtube.com/watch?v=jufiUKMP_bY" class="popup-youtube"><i class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>