import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MailService {
    private url = `${environment.API_URI}/email`;
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private http: HttpClient) {}

    sendEmail(contact) {
        const options = { headers: this.headers };
        const result = this.http.post(this.url, contact, options).subscribe();
    }
}
