<section class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/team-egypt3.jpg" alt="image">
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h2>Our  <span>Approach</span></h2>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Agile methodology applied: SCRUM, Canban</li>
                        <li><i class="fa fa-check"></i>Project management in Trello, Jira</li>
                        <li><i class="fa fa-check"></i>Communication in Slack, Skype, Google Hangout</li>
                        <li><i class="fa fa-check"></i>Communication in English</li>
                        <li><i class="fa fa-check"></i>Easy adaptation to Customer’s processes</li>
                        <li><i class="fa fa-check"></i>Flexible working hours securing ongoing communication with overseas Customers</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>