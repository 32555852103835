<section class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Values</span></h2>
            <p>We believe that better business dynamics requires better software solutions, which we are eager to co-develop with you!.</p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Devoted Work</h3>
                    <p>We strive for perfection and do our best to provide software development services of highest quality.</p>
                    <span>1</span>
                    <div class="card-border"></div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>Honesty And Transparency</h3>
                    <p>We are open with our clients and our employees in terms of our work, our roles and our plans.</p>
                    <span>2</span>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-user-friends"></i>
                    <h3>Willingness To Help Each Other</h3>
                    <p>We create teams, where people collaborate in achieving the goals, and complement each other to secure the best result.</p>
                    <span>3</span>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-handshake"></i>
                    <h3>Respect</h3>
                    <p>We have pleasant atmosphere, where people love working and making friendships.</p>
                    <span>4</span>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-shapes"></i>
                    <h3>Caring Attitude</h3>
                    <p>We care about our employees and want to build long-term relationships, providing everyone with an opportunity to develop, be appreciated, have adequate reward and comfortable working environment.
                        Similarly, we care about our clients, and are oriented at long-lasting trustworthy relations.</p>
                    <span>5</span>
                </div>
            </div>
        </div>
    </div>
</section>