import { Component, OnInit } from '@angular/core';
import Contact from './contact';
import { sendEmail } from './sendEmail';
import {delay} from "rxjs/operators";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  model = new Contact();
  isShowSentMessage: boolean;
  error: {};

  constructor() {}

  ngOnInit() {

  }

  onSendMessage() {
    const { name, email, message } = this.model;
    this.isShowSentMessage = true;
    sendEmail({ name, email, message });
    this.model = new Contact();
    setTimeout( () => { this.isShowSentMessage = false; }, 5000 );
  }
}
