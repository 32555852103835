<section id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>We add tech power to your business</h4>
            <h2>Welcome to <span>Bitcom</span></h2>
            <p>Bitcom started in 2017, and ever since has collaborated with startups and enterprises in building software. We can help you, if you are a startup and want to build an MVP. We will scale your team with our engineers, when you need additional development resources. We are eager to advise you on your digital products.</p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-rocket"></i>
                    </div>
                    <h3>Tech Startup Partner</h3>
                    <p>We assist startups in building MPVs, POCs, prototypes. We perform discovery workshop, co-develop a product vision, UX and architectural concepts.</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-users-cog"></i>
                    </div>
                    <h3>Team Augmentation Provider</h3>
                    <p>We build dedicated development teams. Our professionals in software development, automation testing, UX/UI design, and business analysis will join your team to help you reach your goals.</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-puzzle-piece"></i>
                    </div>
                    <h3>IT Consultant</h3>
                    <p>We help you find the most optimal product architecture, advise on capabilities and constraints of alternative tech stacks, and improve your software solutions.</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</section>