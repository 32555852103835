<section class="funfacts-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="offset-lg-2 offset-md-2 col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="far fa-smile"></i>
                    <h3><span class="odometer" data-count="10">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-shield-alt"></i>
                    <h3><span class="odometer" data-count="17">00</span></h3>
                    <p>Completed Projects</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-globe"></i>
                    <h3><span class="odometer" data-count="8">00</span></h3>
                    <p>Countries Worked With</p>
                </div>
            </div>
        </div>
    </div>
</section>