import { Component, OnInit } from '@angular/core';
import {IBlogPost} from "../../../models/blogPost.interface";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogPosts: IBlogPost[] = [];
  cmsUrl = environment.CMS_URI;

  constructor() { }

  ngOnInit() {
  }

}
