<nav class="navbar navbar-expand-lg navbar-light bg-light header-sticky">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="../../../../assets/svg/logo1.svg"> <span>Bitcom</span>
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <nav class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto" data-toggle="collapse" data-target=".navbar-collapse.show">
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"  routerLink="/">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                <!--<li class="nav-item"><a class="nav-link" href="#team">Team</a></li>-->
                <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                <!--<li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" routerLink="/services" id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false">
                        Services
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" routerLink="/services/web-development">Web Development</a>
                        <a class="dropdown-item" routerLink="/services/mobile-development">Mobile Development</a>
                        <a class="dropdown-item" routerLink="/services/dedicated-team">Dedicated Team</a>
                        <a class="dropdown-item" routerLink="/services/ui-ux-design">UI/UX Design</a>
                        <a class="dropdown-item" routerLink="/services/discovery-phase">Discovery Phase</a>
                    </div>
                </li>-->
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/blog">Blog</a></li>
                <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/careers">Careers</a></li>
                <li class="nav-item"><a class="nav-link" href="/#contact">Contact</a></li>
            </ul>
        </nav>
    </div>
</nav>