<section id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="blog-slides owl-carousel owl-theme">
            <div *ngFor="let post of blogPosts" class="single-blog-item"
                 [ngStyle]="{ 'background-image': 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(&quot;' + cmsUrl + post.cover.url + '&quot;)' }">
                <span>{{post.category}}</span>
                <h3><a routerLink="/blog/{{post.id}}">{{post.title}}</a></h3>
                <p>{{post.date | date}}</p>
                <p>{{post.intro}}</p>
                <a routerLink="/blog/{{post.id}}" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
            </div>
        </div>
    </div>
</section>
