import gql from 'graphql-tag';
import { IBlogPost } from '../models/blogPost.interface';

export interface BlogPostsQueryResponse {
    blogs: IBlogPost[];
}

export const allBlogPostsQuery = () => gql`query allBlogPosts {
    blogs(sort: "date:DESC") {
        title,
        intro,
        date,
        path,
        cover {
            url
        }
    }
}`;


export const blogPostDetailQuery = (path) => gql`query blogPostDetail {
    blogs(where: { path: \"${path}\" }) {
            path,
            title,
            body,
            date,
            category,
            cover {
            url
        },
        authors {
            name,
            title,
            email,
            photo {
                url
            }
        }
    }
}`;