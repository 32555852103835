import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ICareer} from "../../models/careers.interface";
import {CareerService} from "../../services/career.service";
import {ActivatedRoute} from "@angular/router";
import {IContact} from "../../models/contact.interface";
import {MailService} from "../../services/mail.service";

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CareersComponent implements OnInit {
  careers: ICareer[] = [];
  contact: IContact = new IContact();
  isShowSentMessage: boolean;

  constructor(
      private postService: CareerService,
      private mailService: MailService,
      private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.careers = this.route.snapshot.data.careers;
    console.warn('careers', this.route.snapshot.data);
  }

  onSendMessage() {
    const { name, email, message } = this.contact;
    const comment = `Careers: ${message}`;

    this.isShowSentMessage = true;
    this.mailService.sendEmail({
      name,
      email,
      message: comment
    });

    this.contact = new IContact();
    setTimeout( () => { this.isShowSentMessage = false; }, 5000 );
  }

}
