import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { allCareersQuery, CareersQueryResponse } from '../apolloQueries/career.query';
import { ICareer } from '../models/careers.interface';


@Injectable({
    providedIn: 'root'
})
export class CareerService {
    private url = `${environment.CMS_URI}/careers`;

    constructor(private http: HttpClient, private apollo: Apollo) {}

    getCareers(): Observable<ICareer[]> {
        return this.apollo
            .query<CareersQueryResponse>({ query: allCareersQuery() })
            .pipe(
                map(result => {
                    const data = result.data as CareersQueryResponse;
                    return data.careers;
                })
            );
    }
}
