<section class="skill-area ptb-100 bg-fffdfd">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="skill-content">
                    <div class="section-title">
                        <h2>Some of Our <span>Skills</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                    
                    <div class="skills-list">
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Branding</h4>

                                <div class="skill-percentage">
                                    <div class="count-box"><span class="count-text" data-speed="2000" data-stop="92">0</span>%</div>
                                </div>
                            </div>

                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="92"></div></div>
                            </div>
                        </div>
                        
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Marketing</h4>

                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="97">0</span>%</div></div>
                            </div>

                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="97"></div></div>
                            </div>
                        </div>
                        
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Design</h4>

                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="90">0</span>%</div></div>
                            </div>

                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="90"></div></div>
                            </div>
                        </div>
                        
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Development</h4>

                                <div class="skill-percentage"><div class="count-box"><span class="count-text" data-speed="2000" data-stop="95">0</span>%</div></div>
                            </div>

                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="95"></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="col-lg-6 col-md-12">
                <div class="skill-video">
                    <img src="assets/img/video-img.jpg" alt="video-img">

                    <div class="video-btn">
                        <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="popup-youtube"><i class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>