<section id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2><span>Contact</span> Us</h2>
            <p>Ready to start your next project with us? That's great! Give us a call or send us an email and we will get back to you as soon as possible!.</p>
        </div>
        
        <div class="row">
            <div class="col-lg-6 col-md-12">
                
                <app-map></app-map>
                
                <div class="contact-info">
                    <ul>
                        <li><i class="fas fa-map-marker-alt"></i> 23 Holovatskogo Str, Lviv, Ukraine, 79018</li>
                        <li><i class="far fa-envelope"></i> <a href="mailto:info@bitcom.systems">info@bitcom.systems</a></li>
                        <li><i class="fas fa-phone-alt"></i> <a href="tel:+380673689976">+38 (067) 368 99 76</a></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <h3>Stay Connected</h3>
                    <p *ngIf="isShowSentMessage">Thanks for contacting us! We will process your message soon.</p>
                    <form *ngIf="!isShowSentMessage">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control" name="name" [(ngModel)]="model.name">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" class="form-control" name="email" [(ngModel)]="model.email">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Message</label>
                                    <textarea class="form-control" cols="30" rows="4" name="message" [(ngModel)]="model.message" ></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" (click)="onSendMessage()">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>