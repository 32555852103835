<div class="page-title-area item-bg-career ripple-effect jquery-ripples">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-content">
          <h1>Careers</h1>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="active">Careers</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<section id="careers">
  <div class="container">
    <div *ngFor="let career of careers" class="single-career-item">
    <section class="ptb-100">

      <div class="section-title">
        <h2>{{career?.header}}</h2>
        <markdown class="body" [data]="career?.intro" ></markdown>
      </div>
      <div class="career-description">
      <markdown class="body" [data]="career?.description" ></markdown>
      </div>
      <br/>
      <div class="leave-a-reply">
        <h3 class="title mt-0">Contact us:</h3>
        <p *ngIf="isShowSentMessage">Thanks for contacting us! We will process your message soon.</p>
        <div class="row" *ngIf="!isShowSentMessage">
          <div class="col-lg-6 col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" name="name" placeholder="Full Name" [(ngModel)]="contact.name">
            </div>
          </div>

          <div class="col-lg-6 col-md-6">
            <div class="form-group">
              <input type="email" class="form-control" name="email" placeholder="E-Mail" [(ngModel)]="contact.email">
            </div>
          </div>

          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <textarea name="comment" cols="30" rows="5" class="form-control" placeholder="Your Comment" [(ngModel)]="contact.message"></textarea>
            </div>
          </div>

          <div class="col-lg-12 col-md-12">
            <button type="submit" class="btn btn-primary" (click)="onSendMessage()">Submit</button>
          </div>
        </div>
      </div>
    </section>
  </div>
  </div>
</section>