import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BlogPostsComponent } from './components/blog-posts/blog-posts.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { ServicesPageComponent } from './components/services-page/services-page.component';
import { BlogPostsResolve } from './resolvers/blogPosts.resolve';
import { BlogPostResolve } from './resolvers/blogPost.resolve';
import { CareersComponent } from './components/careers/careers.component';
import { CareersResolve } from './resolvers/careers.resolve';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {
        path: 'blog',
        component: BlogPostsComponent,
        resolve: {
            blogPosts: BlogPostsResolve
        }
    },
    {
        path: 'blog/:blogPostPath',
        component: BlogDetailsComponent,
        resolve: {
            blogPost: BlogPostResolve
        }
    },
    {
        path: 'blog-posts',
        component: BlogPostsComponent,
        resolve: {
            blogPosts: BlogPostsResolve
        }
    },
    {
        path: 'blog-posts/:blogPostPath',
        component: BlogDetailsComponent,
        resolve: {
            blogPost: BlogPostResolve
        }
    },
    {
        path: 'careers',
        component: CareersComponent,
        resolve: {
            careers: CareersResolve
        }
    },
    {path: 'services', component: ServicesPageComponent},
    {path: 'services/:id', component: ServicesPageComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
