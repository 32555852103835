import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IBlogPost } from '../models/blogPost.interface';
import { allBlogPostsQuery, BlogPostsQueryResponse, blogPostDetailQuery } from '../apolloQueries/blogPost.query';


@Injectable({
    providedIn: 'root'
})
export class BlogService {
    private url = `${environment.CMS_URI}/blogs`;

    constructor(private http: HttpClient, private apollo: Apollo) {}

    getBlogPosts(): Observable<IBlogPost[]> {
        return this.apollo
          .query<BlogPostsQueryResponse>({ query: allBlogPostsQuery() })
          .pipe(
              map(result => {
                  const data = result.data as BlogPostsQueryResponse;
                  return data.blogs;
              })
          );
    }

    getBlogPost(path: string): Observable<IBlogPost> {
        return this.apollo
            .query<BlogPostsQueryResponse>({ query: blogPostDetailQuery(path) })
            .pipe(
                map(result => {
                    const data = result.data as BlogPostsQueryResponse;
                    return data.blogs[0];
                })
            );
    }
}
