import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';
import { SecurityContext } from '@angular/core';
import { GraphQLModule } from './graphql.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/home-templates/home-one/home-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/common/team/team.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { ServicesComponent } from './components/common/services/services.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { WorkComponent } from './components/common/work/work.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { HomeTwoComponent } from './components/home-templates/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-templates/home-three/home-three.component';
import { HomeFourComponent } from './components/home-templates/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-templates/home-five/home-five.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import { HomeSixComponent } from './components/home-templates/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-templates/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-templates/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-templates/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-templates/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-templates/home-eleven/home-eleven.component';
import { HomeComponent } from './components/home/home.component';
import { HomeThirteenComponent } from './components/home-templates/home-thirteen/home-thirteen.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { BlogPostsComponent } from './components/blog-posts/blog-posts.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { ServicesPageComponent } from './components/services-page/services-page.component';
import { MapComponent } from './components/common/map/map.component';

import { BlogService } from './services/blog.service';
import { MailService } from './services/mail.service';

import { BlogPostsResolve } from './resolvers/blogPosts.resolve';
import { BlogPostResolve } from './resolvers/blogPost.resolve';

import { CareersComponent } from './components/careers/careers.component';
import { CareerService } from './services/career.service';
import { CareersResolve } from './resolvers/careers.resolve';





@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HomeOneComponent,
        NavbarComponent,
        WelcomeComponent,
        AboutComponent,
        StrategyComponent,
        WhoWeAreComponent,
        CtaComponent,
        SkillComponent,
        FunfactsComponent,
        TeamComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        SubscribeComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        ServicesTwoComponent,
        HomeSixComponent,
        HomeSevenComponent,
        HomeEightComponent,
        HomeNineComponent,
        HomeTenComponent,
        HomeElevenComponent,
        HomeComponent,
        HomeThirteenComponent,
        BlogDetailsComponent,
        MapComponent,
        ServicesPageComponent,
        BlogPostsComponent,
        CareersComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        GoogleMapsModule,
        FormsModule,
        CommonModule,
        HttpClientModule,
        MarkdownModule.forRoot({
            loader: HttpClient,
            sanitize: SecurityContext.NONE,
        }),
        GraphQLModule
    ],
    providers: [
        BlogService,
        BlogPostsResolve,
        BlogPostResolve,
        MailService,
        CareerService,
        CareersResolve,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
