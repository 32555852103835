import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BlogService } from './../services/blog.service';
import { IBlogPost } from '../models/blogPost.interface';

@Injectable()
export class BlogPostResolve implements Resolve<IBlogPost> {
    constructor(private postService: BlogService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<IBlogPost> {
        return this.postService.getBlogPost(route.params.blogPostPath);
    }
}
