<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="contact-col col-lg-4 col-md-4 col-sm-12">
                <h4><a routerLink="/"><img src="assets/svg/logo1.svg"> Bitcom</a></h4>
                <p>Bitcom is a full-cycle software development company. Bitcom team is proficient in web and mobile app development, using JavaScript tech stack: Node, React, Angular, React Native, Ionic.</p>
                <!--<div class="column-item"><i class="fas fa-map-marker-alt"></i> 23 Holovatskogo str, Lviv, Ukraine, 79018</div>
                <div class="column-item"><i class="far fa-envelope"></i> <a href="mailto:info@bitcom.systems">info@bitcom.systems</a></div>
                <div class="column-item"><i class="fas fa-phone-alt"></i> <a href="tel:+380673689976">+38 (067) 368 99 76</a></div>-->
            </div>
            <div class="services-col col-lg-4 col-md-4 col-sm-12">
                <h4>Contacts</h4>
                <div class="column-item"><i class="fas fa-map-marker-alt"></i> 23 Holovatskogo Str, Lviv, Ukraine, 79018</div>
                <div class="column-item"><i class="far fa-envelope"></i> <a href="mailto:info@bitcom.systems">info@bitcom.systems</a></div>
                <div class="column-item"><i class="fas fa-phone-alt"></i> <a href="tel:+380673689976">+38 (067) 368 99 76</a></div>
                <!--<h4><a routerLink="/services">Services</a></h4>
                <div class="column-item"><a routerLink="/services/web-development">Web Development</a></div>
                <div class="column-item"><a routerLink="/services/mobile-development">Mobile Development</a></div>
                <div class="column-item"><a routerLink="/services/dedicated-team">Dedicated Team</a></div>
                <div class="column-item"><a routerLink="/services/ui-ux-design">UI/UX Design</a></div>
                <div class="column-item"><a routerLink="/services/quality-assurance">Quality Assurance</a></div>
                <div class="column-item"><a routerLink="/services/discovery-phase">Discovery Phase</a></div>-->
            </div>
            <div class="other-col col-lg-4 col-md-4 col-sm-12">
                <!--<div class="column-item"><a routerLink="/technology">Technology</a></div>
                <div class="column-item"><a routerLink="/team">Team</a></div>
                <div class="column-item"><a routerLink="/career">Career</a></div>
                <div class="column-item"><a href="/blog">Blog</a></div>
                <div class="column-item"><a routerLink="/privacy-policy">Privacy Policy</a></div>-->
                <div class="column-item">
                  <ul>
                    <li><a href="https://www.facebook.com/BS.bitcom.systems" class="fab fa-facebook-f"></a></li>
                    <li><a href="https://www.linkedin.com/company/bitcom-systems" class="fab fa-linkedin-in"></a></li>
                  </ul>
                </div>
                <div class="column-item">
                    <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="50" data-darkbg="1" data-clutchcompany-id="1244120"></div>
                </div>
            </div>
        </div>

        <p><i class="far fa-copyright"></i>2021 Bitcom | All Rights Reserved.</p>
    </div>
</footer>

<div class="go-top"><i class="fas fa-chevron-up"></i></div>
