import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MailService } from '../../services/mail.service';
import { BlogService } from '../../services/blog.service';
import {IContact} from '../../models/contact.interface';
import { IBlogPost } from '../../models/blogPost.interface';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogDetailsComponent implements OnInit {
  blogPost: IBlogPost;
  contact: IContact = new IContact();
  cmsUrl = environment.CMS_URI;
  date = '';
  isShowSentMessage: boolean;

  constructor(
      private postService: BlogService,
      private mailService: MailService,
      private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.blogPost = this.route.snapshot.data.blogPost;
  }

  onSendMessage() {
    const { name, email, message } = this.contact;
    const comment = `{this.blogPost.title}: ${message}`;

    this.isShowSentMessage = true;
    this.mailService.sendEmail({
      name,
      email,
      message: comment
    });

    this.contact = new IContact();
    setTimeout( () => { this.isShowSentMessage = false; }, 5000 );
  }

}
